"use client";
import PageViewEvent from "@/app/lib/PageViewEvent";
import { MetadataType } from "@/app/types/MetadataType";
import Link from "next/link";
import { usePathname } from "next/navigation";


export default function NotFound() {
	const pagePath = usePathname();

	const dlData: MetadataType = {
		event: "pageView",
		author: "N+",
		countWidget: undefined,
		embeds: undefined,
		etiquetas: undefined,
		page_location: `${process.env.NEXT_PUBLIC_BASE_URL}${pagePath || "/"}`,
		page_path: `${pagePath}`,
		page_title: undefined,
		page_previous: undefined,
		previousBlock: undefined,
		previousTipoElement: undefined,
		seccion: `${pagePath}/`?.replace(/\/+$/, "/"),
		tipoContenido: `404 not found`,
	};

	return (
		<>
			<PageViewEvent metadata={dlData} />
			<div
				style={{
					backgroundImage: "url(/image/404.jpg)",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					height: "calc(100vh - 105px)",
					display: "grid",
					placeItems: "center",
				}}
			>
				<div style={{ textAlign: "center" }}>
					<h1
						style={{
							marginBottom: "8px",
							color: "#F1EFE3",
							fontSize: "28px",
							fontWeight: "900",
							lineHeight: "28px",
						}}
					>
						Lo sentimos,
						<br />
						esta página no existe
					</h1>
					<p
						style={{
							marginBottom: "24px",
							color: "#E3E7EB",
							fontSize: "16px",
							fontWeight: "400",
							lineHeight: "22px",
						}}
					>
						Puedes regresar o intentar en otro enlace
					</p>
					<Link
						style={{
							color: "#ffffff",
							fontSize: "12px",
							lineHeight: "16px",
							letterSpacing: "-0.24px",
							fontWeight: " 400",
							border: "1px solid #BAC0CB",
							padding: "8px 16px",
							backgroundColor: "#305065",
						}}
						href="/"
					>
						Volver al inicio
					</Link>
				</div>
			</div>
		</>
	);
}
